
@media print {

  .not-visible-when-printing, #cookiescript_badge {
    display: none !important;
  }

  .only-visible-when-printing {
    display: initial !important;
  }

}